<template>
  <div v-if="visible" class="row justify-content-center">
    <div class="col-lg-8 col-md-10 col-12">
      <div id="loadingBanner" class="alert alert-success text-center" role="alert">
        <h3 style="padding-left: 0.75rem;">
          <CSpinner></CSpinner>
          <slot></slot>
        </h3>
      </div>
    </div>
  </div>
</template>

<script setup>
import {CSpinner} from "@coreui/bootstrap-vue";
</script>

<script>
export default {
  props: {
    visible: Boolean
  }
}
</script>