<template>
  <CLink href="#" color="primary" @click="() => { visibleLiveDemo = true }">{{ linkText }}</CLink>
  <transition name="fade">
    <div>
      <CModal :visible="visibleLiveDemo" @close="() => { visibleLiveDemo = false }">
        <CModalHeader>
          <CModalTitle>{{ modalTitle }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <slot></slot>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" @click="() => { visibleLiveDemo = false }">
            {{ buttonText }}
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  </transition>
</template>

<script>
import {CButton, CLink, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";

export default {
  components: {CLink, CModalFooter, CModalBody, CModalTitle, CModalHeader, CModal, CButton},
  data() {
    return {
      visibleLiveDemo: false,
    }
  },
  props: {
    linkText: String,
    modalTitle: String,
    buttonText: String,
  }
}
</script>
