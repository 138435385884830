<template>
  <div id="fb-root"></div>
  <div class="recipe card mb-3">
    <div class="card-header">
      <div class="d-flex align-self-stretch">
        <div class="p-2 flex-grow-1 align-items-center">
          <h1 class="recipe-title">{{ recipe.title }}</h1>
          <p class="recipe-subtitle">Crafted with ❤️ especially for {{ recipe.forUser.split(' ', 1)[0] }} by <span
              class="text-logo">deliciously<span class="ai">.ai</span></span></p>
          <SocialButtons :recipe="recipe"></SocialButtons>
        </div>
        <div class="p-2">
          <s3-image
              :disclaimer-visible="true"
              bucketUrl="https://images.deliciously.ai"
              :imageName="imageUrlFor(recipe)" :retryDelay="5000"/>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-html="pureMarkdown(recipe.description)"></div>
    </div>
  </div>
</template>

<style scoped>
.recipe-title {
  font-size: 2rem;
  font-family: SignPainter-HouseScript, serif;
  font-variant-ligatures: no-common-ligatures;
}

.recipe-subtitle {
  font-size: 80%;
  color: #31363B;
}

@media (min-width: 576px) {
  .recipe-title {
    font-size: 3rem;
  }
}

@media (min-width: 992px) {
  .recipe-title {
    font-size: 4rem;
  }

  span.text-logo {
    font-family: SignPainter-HouseScript, serif;
    font-size: 120%;
  }

  span.text-logo > span.ai {
    color: #6f7680;
  }
}

@media (min-width: 1400px) {
  .recipe-title {
    font-size: 5rem;
  }
}
</style>

<script>
import S3Image from "@/components/utils/MaybeImage.vue";
import {marked} from "marked";
import DOMPurify from 'dompurify';
import SocialButtons from "@/components/utils/SocialButtons.vue";

export default {
  components: {SocialButtons, S3Image},
  props: {
    recipe: {
      title: String,
      description: String,
      forUser: String,
      imageKey: String
    }
  },
  methods: {
    marked,
    imageUrlFor(recipe) {
      return "/" + recipe.imageKey;
    },
    pureMarkdown(str) {
      return DOMPurify.sanitize(marked(str));
    }
  }
}
</script>