<template>
  <CContainer fluid class="nav-container">
    <CRow class="justify-content-end">
      <CCol xs="12" class="nav-signout-container">
        <CurrentUserButton></CurrentUserButton>
        <CButton size="sm" variant="outline" color="danger" @click="emitSignOut">Sign out</CButton>
      </CCol>
    </CRow>
  </CContainer>
</template>

<style scoped>
.nav-container {
  padding-top: 1em;
  padding-bottom: 2em;
}

.nav-signout-container {
  text-align: right;
}
</style>

<script setup>
import {
  CContainer,
  CCol,
  CRow,
  CButton
} from "@coreui/bootstrap-vue";
import CurrentUserButton from "@/components/pages/parts/CurrentUserButton.vue";
</script>

<script>
export default {
  methods: {
    emitSignOut() {
      this.$emit('signOut')
    }
  }
}
</script>