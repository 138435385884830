<template>
  <CModal :visible="intShowModal" content-class-name="show d-block" alignment="center" backdrop="static" :keyboard="false" size="lg">
    <CModalHeader :close-button="false">
      <CModalTitle>
        <h5 class="modal-title" id="cookieConsentLabel">No worries, maybe later? 🤭</h5>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p>
        <strong>We totally get it!</strong> Another subscription is a big deal, and you're not ready for that
        level of commitment right now...
      </p>

      <p>
        No worries! You can continue to use the site and your free credits will refill soon enough.
      </p>

      <p>
        If you decide to subscribe again in the future we'll be right here waiting for you 🥰
      </p>
    </CModalBody>
    <CModalFooter>
      <form action="https://api.deliciously.ai/payments/stripe/checkout" method="POST">
        <input type="hidden" name="lookup_key" value="deliciously-premium"/>
        <input type="hidden" name="access_token" :value="token">
        <button type="submit" class="btn btn-success ms-2" :disabled="token === ''">I changed my mind! I do want to subscribe! 👍</button>
      </form>
      <button type="button" class="btn btn-danger ms-2" @click="close()">I need more time...</button>
    </CModalFooter>
  </CModal>
</template>

<script setup>
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
</script>

<script>
import {Auth} from "aws-amplify";

export default {
  name: "PaymentCancelled",
  props: {
    showModal: Boolean,
    user: Object,
  },
  data() {
    return {
      intShowModal: false,
      token: "",
    }
  },
  created() {
    Auth.currentSession().then(d => d.getAccessToken().getJwtToken()).then(token => {
      this.token = token;
    })
  },
  mounted() {
    this.intShowModal = this.showModal
  },
  methods: {
    close() {
      this.$router.replace({'query': null});
      this.intShowModal = false
    },
  }
}
</script>
