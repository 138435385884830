<template>
  <div class="row justify-content-center">
    <div v-if="recipes" class="recent-recipe-list">
      <div v-for="recipe in recipes" :key="recipe.id" class="col-sm-12">
        <RecipeListItem :recipe="recipe"></RecipeListItem>
      </div>
    </div>
  </div>
</template>

<script setup>
import RecipeListItem from "@/components/recipes/RecipeListItem.vue";
</script>

<script>
export default {
  props: {
    recipes: Array
  }
}
</script>