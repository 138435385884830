<template>
  <div class="recipe card mb-3" role="button" @click="routeToViewFor(recipe)">
    <div class="card-header">
      <div class="d-flex align-self-stretch">
        <div class="p-2">
          <s3-image
              :disclaimer-visible="false"
              bucketUrl="https://images.deliciously.ai"
              :imageName="imageUrlFor(recipe)" :retryDelay="5000"/>
        </div>
        <div class="container">
          <div class="row">
            <div class="p-2 flex-grow-1 align-items-center">
              <h2 class="recipe-title">{{ recipe.title }}</h2>
            </div>
          </div>
          <div class="row">
            <p class="recipe-list-description">{{ recipe.description }}</p>
          </div>
          <div class="row">
            <p class="recipe-subtitle">Crafted with ❤️ especially for {{ recipe.forUser.split(' ', 1)[0] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.recipe-title {
  font-size: 2rem;
  font-family: SignPainter-HouseScript, serif;
  font-variant-ligatures: no-common-ligatures;
}

.recipe-subtitle {
  font-size: 60%;
  color: #31363B;
}

.recipe-list-description {
  display: inline-block;
  overflow: hidden;
  word-break: break-all;
  height: 1.2em;
  line-height: 1.2em;
}

@media (min-width: 576px) {
  .recipe-title {
    font-size: 3rem;
  }
}
</style>


<script setup>
import S3Image from "@/components/utils/MaybeImage.vue";
</script>

<script>
export default {
  props: {
    recipe: { id: String, order: Number, title: String, description: String, forUser: String, imageKey: String }
  },
  methods: {
    imageUrlFor(recipe) {
      return "/" + recipe.imageKey;
    },
    routeToViewFor(recipe) {
      this.$router.push({
        name: 'view',
        params: {
          order: recipe.order,
        },
        state: {
          routerRecipe: {
            ...recipe
          }
        }
      });
    }
  },
}

</script>
