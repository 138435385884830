<template>
  <CModal :visible="showModal" content-class-name="show d-block" alignment="center" backdrop="static" :keyboard="false" size="lg">
    <CModalHeader :close-button="false">
      <CModalTitle>
        <h5 class="modal-title" id="cookieConsentLabel">Oh No! It looks like you're out of free credits! 😢😢😢😢</h5>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p>
        deliciously.ai comes with <strong>{{monthlyCount}}</strong> free recipes every month, and it looks like you've
        used them all up! We hope you enjoyed them 😊! They'll refill automatically on {{readableDate}}.
      </p>
      <p>
        You <em>could</em> wait until then, but since you seem to be loving our content why not get
        <strong>unlimited recipes</strong> with a great value <strong>deliciously.ai premium plan?</strong>
      </p>
    </CModalBody>
    <CModalFooter>
      <form action="https://api.deliciously.ai/payments/stripe/checkout" method="POST">
        <input type="hidden" name="lookup_key" value="deliciously-premium"/>
        <input type="hidden" name="access_token" :value="token">
        <button type="submit" class="btn btn-success ms-2" :disabled="token === ''">Yes! I want <strong>unlimited</strong> recipes! 👍</button>
      </form>
      <button type="button" class="btn btn-danger ms-2" @click="close()">Thanks, I'll wait...</button>
    </CModalFooter>
  </CModal>
</template>

<script setup>
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
</script>

<script>
import {Auth} from "aws-amplify";

export default {
  name: "SubscribeModal",
  props: {
    monthlyCount: Number,
    nextAllocation: String,
    user: Object,
  },
  data() {
    return {
      showModal: false,
      token: "",
    }
  },
  computed: {
    readableDate() {
      return new Date(Date.parse(this.nextAllocation)).toLocaleString(
          undefined, { year: "numeric", month: "long", day: "numeric" });
    }
  },
  expose: [
      'show'
  ],
  created() {
    Auth.currentSession().then(d => d.getAccessToken().getJwtToken()).then(token => {
      this.token = token;
    })
  },
  methods: {
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
  }
}
</script>
