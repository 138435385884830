<template>
  <CModal :visible="intShowModal" content-class-name="show d-block" alignment="center" :backdrop="true" :keyboard="false" size="lg">
    <CModalHeader :close-button="false">
      <CModalTitle>
        <h5 class="modal-title" id="cookieConsentLabel">Hooray! Welcome to the party! 🥳</h5>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p>
        <strong>Thanks for subscribing!</strong> You're now among the top 0.0863% of the population when it comes
        to delicious AI recipes!
      </p>
    </CModalBody>
    <CModalFooter>
      <button type="button" class="btn btn-success ms-2" @click="close()">Let's get started!</button>
    </CModalFooter>
  </CModal>
</template>

<script setup>
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
</script>

<script>
export default {
  name: "PaymentSuccess",
  props: {
    showModal: Boolean
  },
  data() {
    return {
      intShowModal: false
    }
  },
  mounted() {
    this.intShowModal = this.showModal
  },
  methods: {
    close() {
      this.$router.replace({'query': null});
      this.intShowModal = false
    },
  }
}
</script>
