<template>
  <div v-if="visible" class="row justify-content-center">
    <div class="col-lg-8 col-md-10 col-12">
      <div id="errorBanner" class="alert alert-danger" role="alert">
        <i class="bi bi-exclamation-triangle"></i>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean
  }
}
</script>