import { createApp } from 'vue';
import { BootstrapVue } from '@coreui/bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css"

import { Amplify } from 'aws-amplify';

import App from './App.vue';
import router from './router'
import './custom.css';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

createApp(App)
    .use(router)
    .use(BootstrapVue)
    .mount('#app');

Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_9gNy8c3SQ',
        userPoolWebClientId: '5n1drinceria5dpks73lvknv95',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    oauth: {
        domain: "deliciously-ai.auth.eu-west-1.amazoncognito.com",
        redirectSignIn: isLocalhost ? "http://localhost:8080/create" : "https://www.deliciously.ai/create",
        redirectSignOut: isLocalhost ? "http://localhost:8080" : "https://www.deliciously.ai",
        scope: [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        responseType: "code",
    }
});

