import { createRouter, createWebHistory } from 'vue-router'
import RecentRecipes from "@/components/pages/RecentRecipes.vue";
import RecipeGenerator from "@/components/pages/RecipeGenerator.vue";
import ViewRecipe from "@/components/pages/ViewRecipe.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: RecentRecipes
  },
  {
    path: '/create',
    name: 'create',
    component: RecipeGenerator
  },
  {
    path: '/view/:order',
    name: 'view',
    component: ViewRecipe,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(() => { window.scrollTo(0, 0) });

export default router
