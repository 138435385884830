<template>
  <CModal :visible="showModal" content-class-name="show d-block" alignment="center" :backdrop="true" :keyboard="false" size="lg">
    <CModalHeader :close-button="false">
      <CModalTitle>
        <h5 class="modal-title" id="cookieConsentLabel">How it works 🤓</h5>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p>
        <strong>Welcome to our magical culinary adventure, where cooking becomes a delightful journey with just a few
          taps!</strong>
      </p>
      <p>
        deliciously.ai combines the culinary prowess of both ChatGPT 3.5 and 4 to transform your list of ingredients
        into mouthwatering recipes that will leave you craving more.
      </p>
      <p>
        So, how does it work? It's as simple as waving a wand (or tapping a screen)! Imagine you have a random
        assortment of ingredients sitting in your pantry — maybe some apples, chicken, broccoli, and a splash of soy
        sauce. Just input these ingredients into our app, and like magic, ChatGPT leaps into action.
      </p>
      <p>
        First, ChatGPT sprinkles its culinary expertise on your list, brainstorming and pondering creative ways to
        combine those ingredients into a delicious masterpiece. It consults its vast knowledge of recipes, techniques,
        and flavors from around the globe, all while keeping your taste preferences in mind.
      </p>
      <p>
        Once ChatGPT has cooked up a brilliant idea, it starts crafting a recipe step-by-step. It may suggest sautéing
        the chicken with a medley of spices until it's perfectly golden, then tossing in the broccoli for a healthy
        crunch. Next, it might recommend transforming those juicy apples into a tangy sauce with a hint of sweetness,
        adding a delightful twist to the dish. And with a dash of soy sauce, the flavors harmonize, creating a symphony
        of taste that will tickle your taste buds.
      </p>
      <p>
        But wait, there's more! With the introduction of ChatGPT 4, our app gets an upgrade worthy of a Michelin star.
        ChatGPT 4 adds an extra layer of culinary intuition, allowing it to refine and customize the recipe
        even further.
      </p>
      <p>
        So, whether you're a seasoned chef seeking fresh inspiration or a novice cook looking to transform simple
        ingredients into culinary marvels, deliciously.ai, powered by the dynamic duo of ChatGPT 3.5 and 4, is your
        AI kitchen companion.
      </p>
      <p>
        Get ready to embark on a taste adventure that will make your friends marvel at your culinary prowess 😊
      </p>
      <p>
        <strong>Bon appétit!</strong>
      </p>
    </CModalBody>
    <CModalFooter>
      <button type="button" class="btn btn-primary btn-sm ms-2" @click="close()">Close</button>
      <a href="https://openai.com/product/chatgpt" target="_blank">
        <button type="button" class="btn btn-outline-primary btn-sm ms-2">Learn more</button>
      </a>
    </CModalFooter>
  </CModal>
</template>

<script setup>
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
</script>

<script>
export default {
  name: "HowItWorks",
  data() {
    return {
      showModal: false
    }
  },
  expose: [
      'show'
  ],
  methods: {
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    }
  }
}
</script>
