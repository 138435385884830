<template>
  <div id="app">
    <div id="mainContainer">
      <router-view/>
      <footer class="text-center mt-5 mb-3 small">
        <hr class="border-secondary">
        <p style="font-size: 75%;">
          <strong>&copy;2023 deliciously.ai</strong><br>
          Recipes are generated without human oversight. We take no responsibility for correctness or completeness.<br>
          We cannot guarantee recipes are free of allergens or suitable for any particular dietary requirement.<br>
          You must ensure recipes are suitable, achievable and safe prior to use. <br>
          Content provided for entertainment purposes only.<br>
          <a href="#" @click="showHowItWorks()">How it works</a> | <DisclaimerModal/>
        </p>
        <p style="font-size: 75%;">
          The Really Old-School Company Limited T/A deliciously.ai. Registered in England & Wales Nᴼ 12596175. <a href="mailto:info@reallyoldschool.com">Contact</a>.
        </p>
      </footer>
    </div>
    <cookie-consent></cookie-consent>
    <how-it-works ref="howItWorks"></how-it-works>
  </div>
</template>

<style>
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script setup>
import CookieConsent from "@/components/modals/CookieConsent.vue";
import DisclaimerModal from "@/components/modals/DisclaimerModal.vue";
import HowItWorks from "@/components/modals/HowItWorks.vue";
</script>

<script>
export default {
  methods: {
    showHowItWorks() {
      this.$refs.howItWorks.show();
    }
  }
}
</script>
