<template>
  <div id="recentRecipes" class="container">

    <NonGeneratePageHeader></NonGeneratePageHeader>

    <div class="row justify-content-center">
      <div class="container-fluid">
        <ErrorCallout :visible="loadError">
          <strong style="padding-left: 0.75rem;">Oh No!</strong> Something went wrong while finding awesome recent recipes for you! ☹️<br><br>
          We'll get it fixed ASAP, please check back later (or why not <router-link to="/create">create a recipe</router-link> in the meantime?).
        </ErrorCallout>

        <LoadingCallout :visible="isLoading">
          Hold tight, finding some awesome recent recipes for you 😊
        </LoadingCallout>

        <div v-if="(recipes && recipes.length === 0) && !isLoading">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10 col-12">
              <div id="betaBanner" class="alert alert-warning" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
                <strong style="padding-left: 0.75rem;">Oh no!</strong> We couldn't find any recent recipes for you. Please check back later! 😊
              </div>
            </div>
          </div>
        </div>

        <RecipeList :recipes="recipes"></RecipeList>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NonGeneratePageHeader from "@/components/pages/parts/NonGeneratePageHeader.vue";
import LoadingCallout from "@/components/callouts/LoadingCallout.vue";
import ErrorCallout from "@/components/callouts/ErrorCallout.vue";
import RecipeList from "@/components/recipes/RecipeList.vue";

const backendUrl = "https://api.deliciously.ai/recipes";

export default {
  components: {RecipeList, ErrorCallout, LoadingCallout, NonGeneratePageHeader},
  data() {
    return {
      isLoading: false,
      loadError: false,
      recipes: null,
    }
  },
  mounted() {
    if (!this.recipes) {
      this.getRecentRecipes();
    }
  },
  methods: {
    async getRecentRecipes() {
      this.isLoading = true;
      this.loadError = false;
      try {
        const response = await axios.get(backendUrl);

        if (response.status === 200) {
          this.recipes = response.data;
        } else {
          this.loadError = true;
        }
      } catch (error) {
        console.error(error);
        this.loadError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>
