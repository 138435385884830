<template>
  <div v-if="loading" class="inline">
  <CButton class="welcome-button btn-outline-secondary" size="sm" disabled>
      <CSpinner size="sm"></CSpinner> Loading...
  </CButton>
  </div>
  <div v-else class="inline">
    <div v-if="user.is_paid" class="inline">
      <form action="https://api.deliciously.ai/payments/stripe/manage_subscription" method="POST" class="inline">
        <CButton class="welcome-button btn-outline-secondary" size="sm" type="submit" title="Manage your subscription" :disabled="token === ''">
          <i class="left-bi bi bi-person-circle"></i>
          <i class="bi bi-coin"></i>
          {{user.display_name}}
        </CButton>
        <input name="access_token" type="hidden" :value="token">
      </form>
    </div>
    <div v-else class="inline">
      <span :title="user.is_staff ? 'Cannot manage subscription for staff account!' : 'You have ' + user.free_credits_remaining + ' credits left this month!'">
        <CButton class="welcome-button btn-outline-secondary disabled" size="sm" type="submit">
            <i class="left-bi bi bi-person-circle"></i>
            <i :class="user.is_staff ? 'bi bi-coin' : `bi ${countRemainingClass(user)}`"></i>
            {{user.display_name}}
        </CButton>
      </span>
    </div>
  </div>
</template>

<style scoped>
.welcome-button {
  margin-right: 0.25em;
}
.left-bi {
  margin-right: 0.25em;
}
.inline {
  display: inline;
}
</style>

<script setup>
import { CButton, CSpinner } from "@coreui/bootstrap-vue";
</script>

<script>
import axios from "axios";
import {Auth} from "aws-amplify";

export default {
  data() {
    return {
      loading: true,
      user: null,
      token: ""
    };
  },
  created() {
    Auth.currentSession().then(d => d.getAccessToken().getJwtToken()).then(token => {
      this.token = token;
      this.fetchUser();
    })
  },
  methods: {
    async fetchUser() {
      this.loading = true;
        axios.get("https://api.deliciously.ai/profile", {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }).then(response => {
        this.loading = false;
        this.user = response.data.user;
      });
    },
    countRemainingClass(user) {
      if (user.free_credits_remaining === 0) {
        return "bi-0-circle-fill";
      } else {
        return `bi-${user.free_credits_remaining}-circle`
      }
    }
  }
}
</script>