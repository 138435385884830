<template>
  <div id="viewRecipe" class="container">

    <NonGeneratePageHeader></NonGeneratePageHeader>

    <div class="row justify-content-center">
      <div class="container-fluid">

        <ErrorCallout :visible="loadError">
          <strong style="padding-left: 0.75rem;">Oh No!</strong> Something went wrong while finding that recipe for you! ☹️<br><br>
          We'll get it fixed ASAP, please check back later (or why not <router-link to="/create">create a recipe</router-link> in the meantime?).
        </ErrorCallout>

        <LoadingCallout :visible="isLoading">
          Hold tight, finding that detailed recipe for you! 😊
        </LoadingCallout>

        <div class="row justify-content-center">
          <div v-if="!isLoading && recipe">
            <RecipeView :recipe="recipe"></RecipeView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import RecipeView from "@/components/recipes/RecipeView.vue";
import NonGeneratePageHeader from "@/components/pages/parts/NonGeneratePageHeader.vue";
import ErrorCallout from "@/components/callouts/ErrorCallout.vue";
import LoadingCallout from "@/components/callouts/LoadingCallout.vue";
</script>

<script>
import axios from "axios";

export default {
  data() {
    return {
      recipe: null,
      isLoading: true,
      loadError: false,
    }
  },
  mounted() {
    this.isLoading = true;

    if (!this.recipe) {
      if (!history.state.routerRecipe) {
        axios.get("https://api.deliciously.ai/recipes", {
          params: {
            just: this.$route.params.order
          }
        }).then(response => {
          if (response.status === 200) {
            this.recipe = response.data[0]
          } else {
            this.loadError = true;
          }
          this.isLoading = false;
        });
      } else {
        this.recipe = {
          "imageKey": history.state.routerRecipe.imageKey,
          "title": history.state.routerRecipe.title,
          "description": history.state.routerRecipe.description,
          "forUser": history.state.routerRecipe.forUser,
          "dislikes" : history.state.routerRecipe.dislikes,
          "id" : history.state.routerRecipe.id,
          "likes" : history.state.routerRecipe.likes,
          "order" : history.state.routerRecipe.order,
        }
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }
}
</script>