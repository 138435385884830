<template>
  <authenticator :sign-up-attributes="['name']" :form-fields="signUpFormFields" :socialProviders="['google']">
    <template v-slot:header>
      <div class="container-fluid main-header">
        <div class="row justify-content-center">
          <div class="col-8">
            <img id="logo" class="img-fluid" src="../../assets/deliciously.svg" alt="deliciously.ai">
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-12">
            <div id="signInBanner" class="alert alert-secondary" role="alert">
              <p><strong>Sign in</strong> with Google or your deliciously.ai account, or click
              <strong>Create Account</strong> to sign up for a <strong>free</strong> account if you don't have one already!</p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:sign-up-header>
      <div class="container-fluid sign-up-beta-warning ">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-12">
            <div id="betaBanner" class="alert alert-success" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
              <strong style="padding-left: 0.75rem;">Create a free account</strong> to get started with <strong>3 free personalised recipes every month!</strong>
              <p></p>
              <p>
                We'll never sell your details or use them to spam you.
              </p>

            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot="{ user, signOut }">
      <div id="recipeGenerator" class="container">
        <TopNav @signOut="signOut()"></TopNav>
        <div id="logo" class="row justify-content-center">
          <div class="col-10 col-md-5 text-center">
            <img class="img-fluid" src="../../assets/deliciously.svg" alt="deliciously.ai">
            <p class="logo-blurb text-end">Your AI kitchen companion! 👩‍🍳</p>
            <router-link to="/"><CButton class="recent-link" variant="outline" color="secondary" size="sm"><i class="bi-arrow-left"></i>View recent recipes</CButton></router-link>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-10 col-12">
            <div id="betaBanner" class="alert alert-success" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
              <strong style="padding-left: 0.75rem;">Enter a few ingredients below</strong> (up to four or five works best) and your
              personal AI chef will get to work to create an awesome personalised recipe for you, complete with AI generated serving suggestion!
              <p></p>
              <p>Once you generate a recipe you'll be able share it, and the best ones will be shown on the front page! 🥳</p>
            </div>
          </div>
        </div>
        <div class="input-group mb-3 mt-4">
          <input
              type="text"
              class="form-control"
              v-model="ingredients"
              placeholder="Enter ingredients, separated by commas"
              :disabled="isLoading"
              @keyup.enter="getRecipes"
          />
          <button class="btn btn-primary" @click="getRecipes" :disabled="isLoading">
            <CSpinner v-if="isLoading" small type="grow"></CSpinner>
            <span v-else>Create recipe</span>
          </button>
        </div>
        <div v-if="isLoading">
          <div class="card">
            <div class="card-header">
              <h3>Please stand by ⏱️</h3>
            </div>
            <div class="card-body">
              Your A.I. chef is hard at work in the kitchen cooking up something awesome!
            </div>
          </div>
        </div>
        <div v-if="!isLoading && recipes">
          <RecipeView v-for="recipe in recipes" :key="recipe.id" :recipe="recipe"></RecipeView>
        </div>
        <div v-else>
          <div class="row justify-content-center">
            <div class="col-12 lg-col-10">
              <hr class="border-secondary">
              <p class="text-center"><strong>deliciously.ai</strong> is your A.I. kitchen companion!</p>
              <p class="text-center small">Simply enter a list of ingredients
                above, separated by commas, and <strong>deliciously.ai</strong> will create a brand-new recipe suggestion for you!</p>
            </div>
          </div>
        </div>
      </div>
      <subscribe-modal ref="subscribeModal" :next-allocation="subscribeNextAllocDue" :monthly-count="subscribeNextAllocAmt" :user="user"></subscribe-modal>
      <payment-success :show-modal="isPaymentSuccess()"></payment-success>
      <payment-cancelled :show-modal="isPaymentCancelled()" :user="user"></payment-cancelled>
    </template>
  </authenticator>
</template>

<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import TopNav from "@/components/pages/parts/TopNav.vue";
import RecipeView from "@/components/recipes/RecipeView.vue";
import SubscribeModal from "@/components/modals/SubscribeModal.vue";
import PaymentSuccess from "@/components/modals/PaymentSuccess.vue";
import PaymentCancelled from "@/components/modals/PaymentCancelled.vue";

const signUpFormFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
      label: "Email",
      order:1
    },
    password: {
      order: 2
    },
  },
  signUp: {
    username: {
      placeholder: "Enter your email",
      label: "Email",
      order:1
    },
    name: {
      order: 2
    },
    password: {
      order: 3
    },
    confirm_password: {
      order: 4
    }
  },
}
</script>

<script>
import axios from 'axios';
import {CSpinner, CButton} from "@coreui/bootstrap-vue";
import {Auth} from "aws-amplify";

//const backendUrl = 'http://localhost:3000/api/generate-recipes';                        /* NPM SERVE */
//const backendUrl = 'https://3pj4emuqmd3kvixudkhqseood40yqtck.lambda-url.eu-west-1.on.aws' /* FUNCTION URL */
const backendUrl = 'https://generate.deliciously.ai'

export default {
  components: {CSpinner, CButton},
  data() {
    return {
      ingredients: '',
      recipes: null,
      isLoading: false,
      disclaimerVisible: false,
      subscribeNextAllocDue: null,
      subscribeNextAllocAmt: 0
    };
  },
  methods: {
    isPaymentSuccess() {
      return this.$route.query.paymentSuccess !== undefined
    },
    isPaymentCancelled() {
      return this.$route.query.paymentCancelled !== undefined
    },
    async getRecipes() {
      this.isLoading = true;
      try {
        const response = await axios.post(backendUrl, {
          ingredients: this.ingredients,
        }, {
          headers: { Authorization: `Bearer ${await Auth.currentSession().then(d => d.getAccessToken().getJwtToken().trim())}`}
        });

        if (response.status === 200) {
          this.recipes = [response.data];
        }

      } catch (error) {
        console.error(error);
        if (error.response) {
          switch (error.response.status) {
            case 401:
              alert('Oh no ☹️!\n\nIt seems that there was a problem with authorization of your recipe request.\n\nIf you think this is wrong, please try signing out and back in again.');
              break;
            case 402:
              this.subscribeNextAllocDue = error.response.data.nextFreeAllocationAt;
              this.subscribeNextAllocAmt = error.response.data.nextAllocationAmount;
              this.$refs.subscribeModal.show();
              break;
            case 500:
              alert("Well, this is embarrassing 😳.\n\nWe seem to be having some issues, which can happen during beta testing.\n\nWe're probably working on it, so please try again later 😇");
              break;
            case 502:
              alert("Well, this is embarrassing 😳.\n\nWe seem to be having some issues, which can happen during beta testing.\n\nWe're probably working on it, so please try again later 😇");
              break;
            case 504:
              alert("Ahem 😬.\n\nIt looks like chef has just popped out for a moment, please try again in a little while.");
              break;
            default:
              alert('How odd 🤨!\n\nan unexpected error occurred while generating recipes 🤔.\n\nPlease try again later.\n\nReport code: ' + error.response.status);
          }
        } else if (error.request) {
          alert("Hmm, it seems like we might be having issues reaching chef right now.\n\nPlease check your connection and try again 🛜");
        } else {
          alert("Oh dear 😞...\n\nThat didn't quite work, there was an error in the web application.\n\nSorry about that, we'll work on getting it fixed ASAP!");
        }
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>

<style scoped>
@font-face {
  font-family: SignPainter-HouseScript;
  src: url('~@/assets/fonts/signpainter-housescript-webfont.ttf');
}

.sign-up-beta-warning {
  margin-top: 1em;
}

.main-header {
  margin-top: 0.5em;
}

.recent-link {
  margin-top: 0.25em;
}

.logo-blurb {
  font-size: 75%;
  color: var(--accent-color);
  padding-right: 2em;
}

#signInBanner {
  font-size: 125%;
}

@media (min-width: 576px) {
  .main-header {
    margin-top: 1em;
  }
}

@media (min-width: 992px) {
  .main-header {
    margin-top: 2em;
  }
}

@media (min-width: 1400px) {
  .main-header {
    margin-top: 3em;
  }
}
</style>