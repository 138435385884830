<script>
import ModalPopup from "@/components/utils/ModalPopup.vue";

export default {
  name: 'DisclaimerModal',
  props: {
    show: Boolean
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: {
    ModalPopup
  }
}
</script>

<template>
  <ModalPopup
      link-text="Legal Notices"
      modal-title="The Really Old-School Company (T/A deliciously.ai) Terms Of Service & AI Disclaimer"
      button-text="Close"
  >
    <div class="align-items-center">
      <div id="disclaimerBanner" class="alert alert-secondary" role="alert">
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
               class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
          <strong style="padding-left: 0.75rem;">IMPORTANT</strong>: Please read this disclaimer carefully
          before using our website and relying on the responses generated by our artificial intelligence (AI)
          systems.
        </p>
        <p><strong>By accessing or using our website, you agree to be bound by the terms and conditions of this
          disclaimer.</strong></p>
        <p>If you do not agree with these terms, you must not use our website.</p>
      </div>

      <ol>
        <li><strong>General Information</strong>
          <ol>
            <li>
              Our website provides a platform where users can ask questions and receive responses generated by AI.
              These responses are based on patterns, algorithms, and data analysis, and may not always be accurate,
              complete, or up-to-date.
            </li>
            <li>
              The AI-generated responses should be treated as informational and should not be considered as
              professional advice, legal opinions, or expert recommendations. We do not guarantee the accuracy,
              reliability, or applicability of the responses to your specific circumstances.
            </li>
          </ol>
        </li>
        <li><strong>No Liability</strong>
          <ol>
            <li>
              <strong>The Really Old-School Company Limited (T/A deliciously.ai)</strong> (<em>hereinafter referred
              to as
              "we," "us," or "our"</em>) shall not be liable for any direct, indirect, incidental, consequential, or
              punitive damages arising out of or in connection with your use of the AI-generated responses on our
              website.
            </li>
            <li>
              We disclaim any liability for errors, omissions, or inaccuracies in the AI-generated responses. You
              acknowledge that you use the website and rely on the responses at your own risk.
            </li>
            <li>
              We do not guarantee the availability, uninterrupted operation, or error-free nature of our website. We
              reserve the right to modify, suspend, or terminate the website's functionality at any time without
              prior
              notice.
            </li>
          </ol>
        </li>
        <li><strong>Intellectual Property</strong>
          <ol>
            <li>
              The AI-generated responses, content, and materials on our website are protected by intellectual
              property laws. You acknowledge and agree that all copyrights, trademarks, trade secrets, and other
              intellectual property rights belong to us or the respective owners.
            </li>
            <li>
              You are granted a limited, non-exclusive, and non-transferable license to access and use the
              AI-generated responses for personal, non-commercial purposes only. You may not reproduce, modify,
              distribute, or create derivative works based on the responses without our prior written consent.
            </li>
          </ol>
        </li>
        <li><strong>User Responsibilities</strong>
          <ol>
            <li>
              You are solely responsible for the use of the AI-generated responses and their interpretation. You
              should exercise your judgment and seek professional advice when appropriate.
            </li>
            <li>
              You agree not to use the website for any unlawful, harmful, or malicious activities, including but not
              limited to transmitting viruses, spam, or unauthorized content.
            </li>
          </ol>
        </li>
        <li><strong>Indemnification</strong>
          <ol>
            <li>
              You agree to indemnify, defend, and hold harmless <strong>The Really Old-School Company Limited
              (T/A deliciously.ai)</strong>, its officers, directors, employees, and affiliates from and against
              any claims, liabilities, damages, losses, costs, or expenses arising out of or in connection with
              your use of the AI-generated responses or any breach of this disclaimer.
            </li>
          </ol>
        </li>
        <li><strong>Governing Law and Jurisdiction</strong>
          <ol>
            <li>
              This disclaimer shall be governed by and construed in accordance with the laws of the United Kingdom.
              Any disputes arising out of or in connection with this disclaimer shall be subject to the exclusive
              jurisdiction of the courts of the United Kingdom.
            </li>
          </ol>
        </li>
      </ol>

      <div id="disclaimerBannerBottom" class="alert alert-primary" role="alert">
        <p>
          If you have any questions or concerns regarding this disclaimer, please contact us at
          info@reallyoldschool.com. By using our website, you acknowledge that you have read,
          understood, and agreed to the terms and conditions of this disclaimer.
        </p>
      </div>
    </div>
  </ModalPopup>
</template>

<style scoped>
ol {
  counter-reset: item;
}

li {
  display: block;
}

li:before {
  content: counters(item, ".") " ";
  counter-increment: item
}
</style>