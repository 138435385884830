<template>
  <div>
    <img class="recipe-image img-fluid" :src="imageSource" alt="image"><br/>
    <p v-if="disclaimerVisible" class="image-disclaimer">(AI serving suggestion for illustration)</p>
  </div>
</template>

<script>
import placeholderImage from '../../assets/recipe-placeholder.png';

export default {
  name: "S3Image",
  props: {
    disclaimerVisible: Boolean,
    bucketUrl: String,
    imageName: String,
    retryDelay: {
      type: Number,
      default: 5000, // default retry delay is 5 seconds
    },
  },
  data() {
    return {
      imageSource: placeholderImage,
      intervalId: null,
    };
  },
  created() {
    // Check if the image exists in the S3 bucket
    this.checkImage();
  },
  methods: {
    checkImage() {
      let self = this;
      // try loading image
      let img = new Image();
      img.src = this.bucketUrl + this.imageName;
      img.onload = function () {
        // If it loads successfully, update the image source and clear retry interval
        self.imageSource = self.bucketUrl + self.imageName;
        clearInterval(self.intervalId);
      };
      img.onerror = function () {
        // If there is an error (e.g. image not found), set up a retry
        if (self.intervalId == null) {
          self.intervalId = setInterval(self.checkImage, self.retryDelay);
        }
      };
    },
  },
  beforeUnmount() {
    // Clear retry interval on component destroy
    if (this.intervalId != null) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style scoped>
img.recipe-image {
  max-height: 5rem;
  border-radius: 1rem;
}

p.image-disclaimer {
  display: none;
}

@media (min-width: 576px) {
  img.recipe-image {
    max-height: 6rem;
    border-radius: 1rem;
  }
}

@media (min-width: 992px) {
  img.recipe-image {
    max-height: 8rem;
    border-radius: 1rem;
  }
}

@media (min-width: 1400px) {
  img.recipe-image {
    max-height: 10rem;
    border-radius: 1rem;
  }
  p.image-disclaimer {
    font-size: 50%;
    text-align: center;
    display: inherit;
  }
}
</style>
