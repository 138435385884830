<template>
  <CModal :visible="showModal" content-class-name="show d-block" alignment="center" :backdrop="true" :keyboard="false" size="lg">
    <CModalHeader :close-button="false">
      <CModalTitle>
        <h5 class="modal-title" id="cookieConsentLabel">Cookies...</h5>
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p>
        <strong>deliciously.ai needs to use a few cookies to make everything work.</strong>
      </p>
      <p>
        We promise we'll never use them for advertising or to let other websites track you, and we won't share your data
        with third parties except when we absolutely have to for things to work (if you use social login, for example).
      </p>
    </CModalBody>
    <CModalFooter>
      <button type="button" class="btn btn-primary btn-sm ms-2" @click="acceptCookies()">Accept necessary cookies</button>
      <a href="https://www.cookiesandyou.com" target="_blank">
        <button type="button" class="btn btn-outline-primary btn-sm ms-2">Learn more</button>
      </a>
    </CModalFooter>
  </CModal>
</template>

<script setup>
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
</script>

<script>
export default {
  name: "S3Image",
  data() {
    return {
      showModal: false
    }
  },
  mounted() {
    this.showModal = localStorage.deliciouslyAiCookieConsent !== "true";
  },
  methods: {
    acceptCookies() {
      localStorage.deliciouslyAiCookieConsent = "true"
      this.showModal = false;
    }
  }
}
</script>
